import React, { useState, useEffect } from "react";
import Home from "./components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Signup";
import AboutUs from "./components/AboutUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import EscrowTerms from "./components/EscrowTerms";
import Terms from "./components/Terms";
import "@fontsource/nunito-sans";
import "@fontsource/raleway";
import SignUpPro from "./components/SignUpPro";
import SignUpProFirst from "./components/SignUpProFirst";

import Dashboard from "./components/Dashboard";
import Property from "./components/Property";

import { getAuth } from "firebase/auth";

import Sell from "./components/Sell";
import Findagent from "./components/Findagent";

import Rent from "./components/Rent";
import Buy from "./components/Buy";
import AgentPropertiesAll from "./components/AgentPropertiesAll";
import Shortlet from "./components/Shortlet";
import Hotel from "./components/Hotel";
import Land from "./components/Land";
import Apartment from "./components/Apartment";
import Savedsearches from "./components/Savedsearches";
import Selecteditem from "./components/Selecteditem";
import SingleBuy from "./components/SingleBuy";
import CommercialSpace from "./components/CommercialSpace";
// Admin Section Agent Start
import HomePage from "./components/admin/agent/HomePage";
import PromoteProperty from "./components/admin/agent/PromoteProperty";
import AgentProperties from "./components/admin/agent/AgentProperties";
import AgentProp from "./components/admin/agent/AgentProp";
import PropertyForm from "./components/admin/agent/PropertyForm";

import HighestRated from "./components/admin/agent/HighestRated";
import AgentProfile from "./components/admin/agent/agentProfile/AgentProfile";
import UploadProperties from "./components/admin/agent/UploadProperties";
import FAQ from "./components/admin/agent/FAQ";
import UpdateProperties from "./components/admin/agent/UpdateProperties";
// Admin Section Agent End
// Admin Section DSA Start
import DASDashboard from "./components/admin/DAS/Dashboard";
import PropertyForm2 from "./components/admin/DAS/PropertyForm2"
import DasProperties from "./components/admin/DAS/DasProperties"
import DasUpdate from "./components/admin/DAS/DasUpdate"
// Admin Section DSA End
//Admin Section User Start

import UserHomePage from "./components/admin/user/UserHomePage";
import UserProfile from "./components/admin/user/userProfile/UserProfile";
import BestProperties from "./components/admin/user/properties/BestDeals";
import SavedProperties from "./components/admin/user/properties/SavedProperties";
import SavedApplication from "./components/admin/user/properties/SavedApplication";
import UserFAQ from "./components/admin/user/UserFAQ";
import BestDeals from "./components/admin/user/properties/BestDeals";
//Admin Section User end

// Main Admin Section Start
import AdminHomePage from "./components/admin/Admin/AdminHomePage";
import AgentVerification from "./components/admin/Admin/AgentVerification";
import Properties from "./components/admin/Admin/Properties";
import AllUser from "./components/admin/Admin/AllUser";
import SelectedPropertiesAgent from "./components/admin/agent/SelectedPropertiesAgent";
import AdminUserProfile from "./components/admin/Admin/userProfile/AdminUserProfile";
import AdminManagerProfile from "./components/admin/Admin/managerProfile/AdminManagerProfile";
import AllAgent from "./components/admin/Admin/AllAgent";
import AgentProfileAdmin from "./components/admin/Admin/agentProfile/AgentProfileAdmin";
import AllPropertymanager from "./components/admin/Admin/AllPropertymanager";
import AllPropertiesAdmin from "./components/admin/Admin/AllPropertiesAdmin";
import UpdatePropertiesAdmin from "./components/admin/Admin/UpdatePropertiesAdmin";
import SelectedPropertiesAdmin from "./components/admin/Admin/SelectedPropertiesAdmin";
import SelectedPropertiesUser from "./components/admin/user/SelectedPropertiesUser";
import SavedPropertiesAdmin from "./components/admin/Admin/HighestPropertiesAdmin";
import HighestRatedAdmin from "./components/admin/Admin/HighestPropertiesAdmin";
import AdminFAQ from "./components/admin/Admin/AdminFAQ";
import AdminProfile from "./components/admin/Admin/adminProfile/AdminProfile";
//Main Admin Section End

//Property Manager Section Begins
import ManagerHomePage from "./components/admin/propertymanager/ManagerHomepage";
import ManagerProperties from "./components/admin/propertymanager/properties/ManagerProperties";
import SelectedPropertiesManager from "./components/admin/propertymanager/properties/SelectedPropertiesManager";
import TenantForm from "./components/admin/propertymanager/properties/TenantForm";
import SaveApplication from "./components/admin/propertymanager/properties/SaveApplication";
import ManagerFAQ from "./components/admin/propertymanager/ManagerFAQ";
import ManagerProfile from "./components/admin/propertymanager/managerProfile/ManagerProfile";
import Help from "./components/Help";
import Notfound from "./components/Notfound";
import AllUsers from "./components/AllUsers";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import db from "./components/Firebase";
import moment from "moment";
import DasPage from "./components/DasPage"
import Blog from "./components/Blog"

//Property Manager Section End

export const UserContext = React.createContext();

function App() {
  const auth = getAuth();
  //const currentUser = auth.currentUser;

  const [user, setUser] = useState(auth.currentUser);

  useEffect(() => {
    if (user) {
      (async () => {
        const docRef = doc(db, "users", user?.uid);
        const docSnap = await getDoc(docRef);
        // setLoading(false);
        // setData(docSnap.data());
        
        let agentData = docSnap.data();

       

        const today = moment(Date.now()).format("l h:mm:ss A");
        var todayDate = new Date(today);
        var endTime = new Date(agentData?.expiring);
        if (todayDate >= endTime) {
          const userRef = doc(db, "users", user?.uid);
          updateDoc(userRef, {
            subscribed: "none",
            expiring: "expired",
          }).then(() => {
            alert(
              `Your ${agentData?.subscribed} Subscription has expired please renew to continue using our premium features`
            );
          });
        }
      })();
    }
  }, [user]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      user ? setUser(user) : setUser(null);
    });
  });
  //getAuth().onAuthStateChanged((user) => {user?setUser(user):setUser(null)})

  //useEffect (() => {const currentUser = JSON.parse(localStorage.getItem('user'));if(currentUser){setUser(currentUser);}},[]);

  return (
    <UserContext.Provider value={user}>
      <BrowserRouter>
        {/*<AuthProvider value ={user}>*/}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/help" element={<Help />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="Login/*" element={<Login />} />
          <Route path="Signup/*" element={<Signup />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="Dashboard/PropertyForm/*" element={<PropertyForm />} />
          <Route path="Dashboard/Property/*" element={<Property />} />

          {/* <Route path="/" element={<App />} />*/}
          <Route path="/Sell" element={<Sell />} />
          <Route path="/das" element={<DasPage />} />
          <Route path="/find-agent" element={<Findagent />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/all-user" element={<AllUsers />} />
          <Route path="/signuppro" element={<SignUpPro />} />
          <Route path="/signuppro-first" element={<SignUpProFirst />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/escrow-terms" element={<EscrowTerms />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/rent" element={<Rent />} />
          <Route path="/shortlet" element={<Shortlet />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/properties/:id" element={<AgentPropertiesAll />} />
          <Route path="/land" element={<Land />} />
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/apartment" element={<Apartment />} />
          <Route path="/commercial-space" element={<CommercialSpace />} />
          <Route path="/products/:productId" element={<Selecteditem />} />
          <Route path="/saved-searches" element={<Savedsearches />} />
          <Route path="/selected-item" element={<Selecteditem />} />
          <Route path="/single-buy" element={<SingleBuy />} />

          {/* Agent Dashboard start */}
          <Route path="/admin/agent" element={<HomePage />} />
          <Route path="/admin/agent/agent-properties" element={<AgentProperties />} />
          <Route path="/admin/agent/properties" element={<AgentProp />} />
          <Route path="/admin/agent/promote" element={<PromoteProperty />} />
          <Route path="/admin/agent/properties/view" element={<SelectedPropertiesAgent />} />
          <Route path="/admin/agent/properties/highest-rated" element={<HighestRated />}/>
          <Route path="/admin/agent/profile" element={<AgentProfile />} />
          <Route path="/admin/agent/properties/upload" element={<UploadProperties />} />
          <Route path="/admin/agent/UpdateProperties" element={<UpdateProperties />}/>
          <Route path="/admin/agent/faqs" element={<FAQ />} />
          {/* Agent Dashboard end */}

           {/* DSA Dashboard start */}
           <Route path="/admin/DAS/Dashboard" element={<DASDashboard />}/>
           <Route path="/admin/DAS/Dashboard/PropertyForm2" element={<PropertyForm2 />}/>
           <Route path="/admin/DAS/Dashboard/DasProperties" element={<DasProperties />}/>
           <Route path="/admin/DAS/Dashboard/DasProperties/DasUpdate" element={<DasUpdate />}/>
         {/* DSA Dashboard end */}
          {/* User Dashboard Start */}
          <Route path="/admin/user" element={<UserHomePage />} />
          <Route path="/admin/user/profile" element={<UserProfile />} />
          <Route path="/admin/user/faqs" element={user ? <UserFAQ /> : <Signup />} />
          <Route path="/admin/user/properties/best-properties" element={<BestProperties />} />
          <Route path="/admin/user/properties/view" element={<SelectedPropertiesUser />} />
          <Route path="/admin/user/properties/saved-properties"element={<SavedProperties />} />
          <Route path="/admin/user/properties/saved-application" element={<SavedApplication />}/>
          <Route path="/admin/user/best-deals" element={<BestDeals />} />
          {/* User Dashboard End */}

          {/* Main Admin Section start */}
          <Route path="/admin" element={<AdminHomePage />}/>
          <Route path="/admin/AgentVerification" element={<AgentVerification />}/>
          <Route path="/admin/Properties" element={<Properties />}/>
          <Route path="/admin/profile" element={user ? <AdminProfile /> : <Signup />} />
          <Route path="/admin/users" element={user ? <AllUser /> : <Signup />} />
          <Route path="/admin/agents"element={user ? <AllAgent /> : <Signup />}/>
          <Route path="/admin/property-managers" element={user ? <AllPropertymanager /> : <Signup />} />
          <Route path="/admin/single-user" element={user ? <AdminUserProfile /> : <Signup />} />
          <Route path="/admin/single-properymanager" element={user ? <AdminManagerProfile /> : <Signup />} />
          <Route path="/admin/single-agent" element={user ? <AgentProfileAdmin /> : <Signup />} />
          <Route path="/admin/properties" element={<AllPropertiesAdmin />} />
          <Route path="/admin/faqs" element={<AdminFAQ />} />
          <Route
            path="/admin/properties/saved-properties"
            element={<SavedPropertiesAdmin />}
          />
          <Route
            path="/admin/properties/highest-rated"
            element={<HighestRatedAdmin />}
          />
          <Route
            path="/admin/properties/view"
            element={<SelectedPropertiesAdmin />}
          />
          <Route
            path="/admin/properties/update"
            element={<UpdatePropertiesAdmin />}
          />
          <Route path="/admin/agent/PropertyForm" element={<PropertyForm />} />
          {/* Main Admin Section end */}

          {/* Property Manager Section Start */}

          {/* Property Manager Section End */}
          <Route path="/admin/propertymanager" element={<ManagerHomePage />} />
          <Route
            path="/admin/propertymanager/properties/view"
            element={<SelectedPropertiesManager />}
          />
          <Route
            path="/admin/propertymanager/properties"
            element={<ManagerProperties />}
          />
          <Route path="/admin/propertymanager/faqs" element={<ManagerFAQ />} />
          <Route
            path="/admin/propertymanager/properties/SaveApplication"
            element={<SaveApplication />}
          />
          <Route
            path="/admin/propertymanager/properties/TenantForm"
            element={<TenantForm />}
          />
          <Route
            path="/admin/propertymanager/profile"
            element={<ManagerProfile />}
          />
          {/* Admin Section end */}
          <Route path="*" element={<Notfound />} />
        </Routes>
        {/*</AuthProvider>*/}
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
