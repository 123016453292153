import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { PaystackButton } from "react-paystack";
import { UserContext } from "../App";
import axios from "axios";
import API_URL from "../config/index";


function DasModal({
  setShow,
  show,
  inspected,
  loading,
  setLoading,
  setErr,
  setMessage,
  data,
  service,
  VIDASvalue,
}) {
  const user = React.useContext(UserContext);
  const navigate = useNavigate();
  const [value, setValue] = useState([5000, 50, 100]);

  const publicKey = "pk_live_3edf5145dc961ec4b761a4b94c25490ed04dd4f6";

  const calculate = () => {
    
    if (VIDASvalue ==='VI&DASONLY' && Number(inspected) === 1) {
      return 50 * 1700;
    } else if (VIDASvalue ==='VI&DASONLY' && Number(inspected) === 3) {
      return 100 * 1700;
    } else if (VIDASvalue ==='VI&DASONLY' ) {
      return ((Number(inspected)*20)+50) * 1700;
    } else if (VIDASvalue ==='VI&DAS+LEGALDUEDELIGENCE' && Number(inspected) === 1) {
      return  100 * 1700;
    } else if (VIDASvalue ==='VI&DAS+LEGALDUEDELIGENCE' && Number(inspected) === 3) {
      return  250 * 1700;
    } else if (VIDASvalue ==='VI&DAS+LEGALDUEDELIGENCE') {
      return ((Number(inspected)*70)+100) * 1700;
    }
  };

  const amount = calculate() * 100;


  const componentRatingsProps = {
    email: data?.email,
    amount,
    metadata: {
      name: data?.name,
      phone: data?.phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
      setLoading(true);
      setErr(null);
      setMessage(null);
      const dnsData = {
        name: data?.name,
        location: data?.location,
        inspected: data?.inspected,
        agentphone: data?.agentphone,
        email_type: "das",
        service: service === true ? "YES" : "NO",
        date: value,
        phone: data?.phone,
        email: data?.email,
        title: `${data?.name} paid for D.A.S, details below`,
        //   phone: data?.phone,
      };

      axios
        .post(API_URL, dnsData)
        .then(() => {
          setLoading(false);
          setShow(false);
          setMessage("Payment successful we'll get back to you soon");

          setTimeout(() => {
            return window.location.reload();
          }, 3000);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
   
    },
    onClose: () => alert("closed"),
  };

  const urlParams = new URLSearchParams(window.location.search);
  const urltype = urlParams.get("type");
  const newParams = urlParams.get("q");


  const handleDas = (e) => {
    e.preventDefault();

    if (!user) {
      navigate("/login?login=redirectTrue", {
        state: {
          redirect: true,
          redirectUrl: `/das`,
        },
      });
    } else {
    }
  };

  return (
    <div id="mode-modal">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Label></Form.Label> */}
          <ul>
            <li>
              {inspected} Property selected
            </li>
          </ul>

          <div className="pt-4 d-flex align-items-center justify-content-between align-content-center">
            <strong>
              Cost for {inspected ? inspected : "15"} properties to be
              inspected:
            </strong>
            <strong>&#8358; {calculate()?.toLocaleString()}</strong>
          </div>
          {!user ? (
            <Button onClick={(e) => handleDas(e)}>Pay now</Button>
          ) : (
            <PaystackButton {...componentRatingsProps} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DasModal;
