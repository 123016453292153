import React, { useState, useEffect } from "react";
import "./styles/navbar.css";
import { Link } from "react-router-dom";
import logo from "../images/chekamlogo.jpeg";
import Box from "@mui/material/Box";
import AccountCircle from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import { doc, getDoc } from "firebase/firestore";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import db from "./Firebase";
import { getAuth, signOut } from "firebase/auth";

function Navbar() {
  const auth = getAuth();

  const [stickyClass, setStickyClass] = useState("");
  const [stickyClass2, setStickyClass2] = useState("");
  const [user, setUser] = useState(auth.currentUser);
  const [userStatus, setUserStatus] = useState("");
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [navShow, setNavShow] = useState("nav-none");

  getAuth()?.onAuthStateChanged((user) => {
    user ? setUser(user) : setUser(null);
  });

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event?.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserStatus(docSnap?.data()?.status);
      } else {
        setUserStatus(null);
      }
    })();
  }, [user]);

  const handleSignOut = () => {
    signOut(getAuth())
      .then(() => {
        // Sign-out successful.
        localStorage.clear();
      })
      .catch((error) => {
        // An error happened.
      });
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    // if (window !== undefined) {
    let windowHeight = window.scrollY;
    windowHeight > 100 ? setStickyClass("header-scrolled") : setStickyClass("");
    windowHeight > 100 ? setStickyClass2("nav-scrolled") : setStickyClass2("");

    // }
  };

  return (
    <>
      <div className={`${navShow}`}>
        <ul className="nav-aside-menu">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/buy">Buy</a>
          </li>
          <li>
            <a href="/rent">Rent</a>
          </li>
          <li>
            <a href="/shortlet">Shortlet</a>
          </li>
          <li>
            <a href="/das">Virtual Inspection & D.A.S</a>
          </li>
          <li>
            <a href="/hotel">Hotels</a>
          </li>
          <li>
            <a href="/about-us">About</a>
          </li>
          <li>
            <a href="/Blog">Blog</a>
          </li>
          <li>
            <a href="/signuppro">List</a>
          </li>
          <li>
            <a href="/help">Help</a>
          </li>
          <li>
            {userStatus === "user" ? (
              <Link to="/admin/user">Dashboard</Link>
            ) : null}
            {userStatus === "agent" ? (
              <Link to="/admin/agent">Dashboard</Link>
            ) : null}
            {userStatus === "property manager" ? (
              <Link to="/admin/propertymanager/ManagerHomepage">Dashboard</Link>
            ) : null}
            {userStatus === "agent and property manager" ? (
              <Link to="/admin/agent">Dashboard</Link>
            ) : null}
            {userStatus === "admin" ? (
              <Link to="/admin/Admin">Dashboard</Link>
            ) : null}
            {userStatus === "das" ? (
              <Link to="/admin/DAS/Dashboard">Dashboard</Link>
            ) : null}
          </li>
          {user ? (
            <li>
              <Typography
                style={{ color: "#FFFFFF", mt: "2px" }}
                onClick={handleSignOut}
              >
                Logout
              </Typography>
            </li>
          ) : (
            <li>
              <a href="/login ">Log in/Sign Up</a>
            </li>
          )}
        </ul>
        <button
          className="nav-close nav-aside-close"
          onClick={() => setNavShow("nav-none")}
        >
          <span></span>
        </button>
      </div>
      <nav
        className={`navbar navbar-expand-lg navbar-dark bg-dark navbar-custom  navbar-text ${stickyClass} ${stickyClass2}`}
      >
        <div className="container-fluid">
          <Link to="/">
            <img
              className=""
              style={{ width: "120px" }}
              src={logo}
              alt="Chekam logo"
            />
            {/* <img className="mobile-logo" style={{ width: "50px" }} src={mobileLogo} /> */}
          </Link>

          <button
            className="navbar-toggler custom-toggler"
            type="button"
            // data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            onClick={() => setNavShow("nav-aside")}
            style={{ background: "#fffff" }}

            // aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul
              className="navbar-nav offset-md-1 me-auto my-2 my-lg-0 navbar-nav-scroll"
            >
              <li
                className="nav-item dropdown"
                style={{ paddingRight: "1rem" }}
              >
                <a className="nav-link" href="/buy">
                  Buy
                </a>
              </li>
              <li
                className="nav-item dropdown"
                style={{ paddingRight: "1rem" }}
              >
                <a className="nav-link" href="/rent">
                  Rent
                </a>
              </li>
              <li
                className="nav-item dropdown"
                style={{ paddingRight: "1rem" }}
              >
                <a className="nav-link" href="/shortlet">
                  Shortlet
                </a>
              </li>
              <li
                className="nav-item dropdown"
                style={{ paddingRight: "1rem" }}
              >
                <a className="nav-link" href="/das" style={{ paddingRight: "1rem", width:'150px', textAlign:'center' }}>
                  V.Insp & D.A.S
                </a>
              </li>
              <li
                className="nav-item dropdown"
                style={{ paddingRight: "1rem" }}
              >
                <a className="nav-link" href="/Hotel">
                  Hotels
                </a>
              </li>

              <li className="nav-item " style={{ paddingRight: "1rem" }}>
                <a className="nav-link" href="/help" s>
                  Help
                </a>
              </li>
              <li className="nav-item " style={{ paddingRight: "1rem" }}>
                <a
                  className="nav-link"
                  href="/about-us"
                  style={{ paddingRight: "1rem" }}
                >
                  About
                </a>
              </li>
              <li className="nav-item " style={{ paddingRight: "1rem" }}>
                <a
                  className="nav-link"
                  href="/Blog"
                  style={{ paddingRight: "1rem" }}
                >
                  Blog
                </a>
              </li>

              <div className="d-flex main-nav" style={{ paddingLeft: "5rem" }}>
                <div style={{ paddingRight: "40px" }}>
                  <Link
                    to="/signuppro"
                    className="btn btn-warning"
                    style={{
                      paddingRight: "40px",
                      paddingLeft: "50px",
                      color: "333333",
                      fontFamily: "Sans-serif",
                    }}
                    // style={{ paddingRight: "50px", paddingLeft: "50px" , color: "333333" , fontWeight: '600', fontFamily: 'sans-serif', textTransform : 'none'}}
                  >
                    List
                  </Link>
                </div>
                {/*{user ? (
                  <Button variant="text" size="small" sx={{ color: "#000000" }}>
                    Saved Searches
                  </Button>
                ) : null}*/}
                {/*{isLoggedIn?<Button color="secondary" variant="contained" size="medium" onClick = {handleSignOut}> Logout</Button>:<Button color="inherit"><Link style={{ color:'black', textDecoration: 'inherit'}} to = "./Login">Sign up/Login</Link></Button>}*/}
                {user ? (
                  <>
                  {userStatus === "user" ? (
                    <button className="btn btn-outline-warning navbar-dashboard-btn">                
                      <Link to="/admin/user">Dashboard</Link>
                    </button>
                  ) : null}

                  {userStatus === "agent" ? (
                    <button className="btn btn-outline-warning avbar-dashboard-btn">
                      <Link to="/admin/agent">Dashboard</Link>
                    </button>
                  ) : null}

                  {userStatus === "property manager" ? (
                    <button className="btn btn-outline-warning navbar-dashboard-btn">
                      <Link to="/admin/propertymanager/ManagerHomepage">
                        Dashboard
                      </Link>
                    </button>
                  ) : null}

                  {userStatus === "agent and property manager" ? (
                    <button className="btn btn-outline-warning navbar-dashboard-btn">
                      <Link to="/admin/agent">Dashboard</Link>
                    </button>
                  ) : null}

                  {userStatus === "admin" ? (
                    <button className="btn btn-outline-warning navbar-dashboard-btn">
                      <Link to="/admin/Admin">Dashboard</Link>
                    </button>
                  ) : null}

                  <Box sx={{ ml: 2 }}>
                    <Tooltip title="Open settings">
                      <IconButton size="small" onClick={handleOpenUserMenu}>
                        {/* <Avatar alt="user" src={logo} />*/}
                        <AccountCircle sx={{ fontSize: "35px" }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem>
                        <Typography onClick={handleSignOut}>Logout</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
 
                  
                  </>


                  // <Box sx={{ ml: 2 }}>
                  //   <Tooltip title="Open settings">
                  //     <IconButton size="small" onClick={handleOpenUserMenu}>
                  //       {/* <Avatar alt="user" src={logo} />*/}
                  //       <AccountCircle sx={{ fontSize: "35px" }} />
                  //     </IconButton>
                  //   </Tooltip>
                  //   <Menu
                  //     sx={{ mt: "45px" }}
                  //     id="menu-appbar"
                  //     anchorEl={anchorElUser}
                  //     anchorOrigin={{
                  //       vertical: "top",
                  //       horizontal: "right",
                  //     }}
                  //     keepMounted
                  //     transformOrigin={{
                  //       vertical: "top",
                  //       horizontal: "right",
                  //     }}
                  //     open={Boolean(anchorElUser)}
                  //     onClose={handleCloseUserMenu}
                  //   >
                  //     {userStatus === "user" ? (
                  //       <MenuItem>
                  //         <Link to="/admin/user">Dashboard</Link>
                  //       </MenuItem>
                  //     ) : null}
                  //     {userStatus === "agent" ? (
                  //       <MenuItem>
                  //         <Link to="/admin/agent">Dashboard</Link>
                  //       </MenuItem>
                  //     ) : null}
                  //     {userStatus === "property manager" ? (
                  //       <MenuItem>
                  //         <Link to="/admin/propertymanager/ManagerHomepage">
                  //           Dashboard
                  //         </Link>
                  //       </MenuItem>
                  //     ) : null}
                  //     {userStatus === "agent and property manager" ? (
                  //       <MenuItem>
                  //         <Link to="/admin/agent">Dashboard</Link>
                  //       </MenuItem>
                  //     ) : null}
                  //     {userStatus === "admin" ? (
                  //       <MenuItem>
                  //         <Link to="/admin/Admin">Dashboard</Link>
                  //       </MenuItem>
                  //     ) : null}
                  //     <MenuItem>
                  //       <Typography onClick={handleSignOut}>Logout</Typography>
                  //     </MenuItem>
                  //   </Menu>
                  // </Box>


                  // <Box sx={{ ml: 2 }}>
                  //   <Tooltip title="Open settings">
                  //     <IconButton size="small" onClick={handleOpenUserMenu}>
                  //       {/* <Avatar alt="user" src={logo} />*/}
                  //       <AccountCircle sx={{ fontSize: "35px" }} />
                  //     </IconButton>
                  //   </Tooltip>
                  //   <Menu
                  //     sx={{ mt: "45px" }}
                  //     id="menu-appbar"
                  //     anchorEl={anchorElUser}
                  //     anchorOrigin={{
                  //       vertical: "top",
                  //       horizontal: "right",
                  //     }}
                  //     keepMounted
                  //     transformOrigin={{
                  //       vertical: "top",
                  //       horizontal: "right",
                  //     }}
                  //     open={Boolean(anchorElUser)}
                  //     onClose={handleCloseUserMenu}
                  //   >
                  //     {userStatus === "user" ? (
                  //       <MenuItem>
                  //         <Link to="/admin/user">Dashboard</Link>
                  //       </MenuItem>
                  //     ) : null}
                  //     {userStatus === "agent" ? (
                  //       <MenuItem>
                  //         <Link to="/admin/agent">Dashboard</Link>
                  //       </MenuItem>
                  //     ) : null}
                  //     {userStatus === "property manager" ? (
                  //       <MenuItem>
                  //         <Link to="/admin/propertymanager/ManagerHomepage">
                  //           Dashboard
                  //         </Link>
                  //       </MenuItem>
                  //     ) : null}
                  //     {userStatus === "agent and property manager" ? (
                  //       <MenuItem>
                  //         <Link to="/admin/agent">Dashboard</Link>
                  //       </MenuItem>
                  //     ) : null}
                  //     {userStatus === "admin" ? (
                  //       <MenuItem>
                  //         <Link to="/admin/Admin">Dashboard</Link>
                  //       </MenuItem>
                  //     ) : null}
                  //     <MenuItem>
                  //       <Typography onClick={handleSignOut}>Logout</Typography>
                  //     </MenuItem>
                  //   </Menu>
                  // </Box>
                ) : (
                  <Button variant="outlined" color="secondary">
                    <Link
                      style={{
                        whiteSpace: "nowrap",
                        color: "#1a1a1a",
                        textDecoration: "none",
                        textTransform: "none",
                        fontFamily: "Sans-serif",
                        fontWeight: "610",
                      }}
                      to="/Login"
                    >
                      Sign Up/Log in
                    </Link>
                  </Button>
                )}
              </div>
            </ul>
          </div>
        </div>

        {/* <!-- Aside Nav --> */}
        {/* <!-- /Aside Nav --> */}
      </nav>
    </>
  );
}

export default Navbar;
