import React, { useState } from "react";
import "./styles/Buy.css";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";
import ReactDateInputs from "react-date-inputs";
import axios from "axios";
import API_URL from "../config/index";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import { CircularProgress } from "@mui/material";
import DasModal from "./DasModal";
import {addDoc,collection,serverTimestamp,query,getDocs,where, updateDoc,} from "firebase/firestore";
import db from "./Firebase";
import PricingModal from "./PricingModal";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function DasPage() {
  const [show, setShow] = useState(false);
  const [VIDASvalue, setVIDASValue] = React.useState('VI&DASONLY');
  const [value, setValue] = useState("");
  const [service, setService] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [data, setData] = useState({
    name: "",
    email: "",
    inspected: "",
    agentphone: "",
    location: "",
    phone: "",
  });


  



  const handleSubmit = (e) => {
    e.preventDefault();
    if (value === "" || value === undefined) {
      setLoading(false);
      setErr("Please the date field is required");
    } else {
      setShow(true);
    }
  };
  const handleContact = (e) => {
    e.preventDefault();
    setLoading(true);
    setErr(null);
    setMessage(null);
    const dnsData = {
      name: data?.name,
      location: data?.location,
      inspected: data?.inspected,
      agentphone: data?.agentphone,
      email_type: "das",
      service: service === true ? "YES" : "NO",
      date: value,
      phone: data?.phone,
      email: data?.email,
      title: `${data?.name} requested for a talk with inspector, details below`,
      //   phone: data?.phone,
    };
    e.preventDefault();
    if (value === "" || value === undefined) {
      setLoading(false);
      setErr("Please the date field is required");
    } else {
      const addDasRequest = async () => {
        const docRef = await addDoc(collection(db, "das"), {
          dnsData
      })
      }
      addDasRequest();
      axios
        .post(API_URL, dnsData)
        .then(() => {
          setLoading(false);
          setMessage(
            "Your request has been received we'll get back to you soon"
          );

          setTimeout(() => {
            return window.location.reload();
          }, 3000);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const handle = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    console.log(newData);
  };

  const handleCheck = (e) => {
    console.log(e.currentTarget.checked);
    if (e.currentTarget.checked) {
      setService(true);
    } else {
      setService(false);
      //   alert("not checked");
    }
  };

  const handleVIDASChange = (event) => {
    setVIDASValue(event.target.value);
  };
  return (
    <div>
      <Helmet>
        <title>Real Estate & Homes For Sale | Chekam</title>
      </Helmet>

      <Navbar />

      {loading && (
        <Dialog
          style={{ overflow: "hidden !important" }}
          open={loading === true}
          onClose={() => console.log("trying to close me")}
        >
          <CircularProgress color="secondary" className="m-5" />
        </Dialog>
      )}

      {err && (
        <Snackbar
          open={err}
          autoHideDuration={20000}
          onClose={() => setErr(null)}
        >
          <Alert
            onClose={() => setErr(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {err}
          </Alert>
        </Snackbar>
      )}
      {message && (
        <Snackbar
          open={message}
          autoHideDuration={20000}
          onClose={() => {
            setMessage(null);
          }}
        >
          <Alert
            onClose={() => {
              setMessage(null);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <div
        className="p-4"
        style={{ backgroundColor: "purple", textAlign: "center" }}
      >
        <h4 className="text-center mx-4 text-white">
          Virtual Inspection & Decision Assistance Service (D.A.S)
        </h4>
        <p className="text-center mx-4 text-white das-paragraph">
          Inspect any property from the comfort of your home with Virtual Inspection.... Use our D.A.S algorithm to make the smartest choice with your money....{" "}
        </p>
        <div><PricingModal /></div>
      </div>
      <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">Service</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={VIDASvalue}
        onChange={handleVIDASChange}
      >
        <FormControlLabel value="VI&DASONLY" control={<Radio />} label="VI & DAS Only" />
        <FormControlLabel value="VI&DAS+LEGALDUEDELIGENCE" control={<Radio />} label="VI & DAS + Legal due deligence" />
      </RadioGroup>
      </FormControl>

      <form className="dns-mobile" onSubmit={(e) => handleSubmit(e)}>
        <div
          className="d-flex mt-5 align-items-center"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{ marginLeft: "10px", fontWeight: "bold" }}
            className="text-center"
          >
            Name
          </p>
          <div style={{ marginRight: "20px" }}>
            <input
              placeholder="enter name"
              name="name"
              required
              value={data?.name}
              onChange={(e) => handle(e)}
              className="form-control"
            />
          </div>
        </div>
        <div
          className="d-flex mt-2 align-items-center"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{ marginLeft: "10px", fontWeight: "bold" }}
            className="text-center"
          >
            Email
          </p>
          <div style={{ marginRight: "20px" }}>
            <input
              placeholder="enter email"
              type="email"
              name="email"
              required
              value={data?.email}
              onChange={(e) => handle(e)}
              className="form-control"
            />
          </div>
        </div>
        <div
          className="d-flex mt-2 align-items-center"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{ marginLeft: "10px", fontWeight: "bold" }}
            className="text-center"
          >
            Phone
          </p>
          <div style={{ marginRight: "20px" }}>
            <input
              placeholder="enter phone"
              type="text"
              required
              className="form-control"
              onChange={(e) => handle(e)}
              value={data?.phone}
              name="phone"
            />
          </div>
        </div>
        <div
          className="d-flex mt-2 align-items-center"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{ marginLeft: "10px", fontWeight: "bold", width: "150px" }}
            className=""
          >
            No of properties to be inspected
          </p>
          <div style={{ marginRight: "20px" }}>
            <input
              placeholder="no of properties to be inspected"
              type="text"
              required
              className="form-control"
              name="inspected"
              value={data?.inspected}
              onChange={(e) => handle(e)}
            />
            <small>e.g Lekki, ajah, ..etc</small>
          </div>
        </div>
        <div
          className="d-flex mt-2 align-items-center"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{ marginLeft: "10px", fontWeight: "bold", width: "150px" }}
            className=""
          >
            Links to properties to be inspected
          </p>
          <div style={{ marginRight: "20px" }}>
            <input
              placeholder="enter links properties"
              name="location"
              value={data?.location}
              onChange={(e) => handle(e)}
              type="text"
              required
              className="form-control"
            />
          </div>
        </div>
        <div
          className="d-flex mt-2 align-items-center"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{ marginLeft: "10px", fontWeight: "bold", width: "150px" }}
            className=""
          >
            Agent phone no
          </p>
          <div style={{ marginRight: "20px" }}>
            <input
              placeholder="enter agent phone no"
              value={data?.agentphone}
              name="agentphone"
              onChange={(e) => handle(e)}
              type="text"
              required
              className="form-control"
            />
          </div>
        </div>
        <div
          className="d-flex mt-2 align-items-center"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{ marginLeft: "10px", fontWeight: "bold", width: "150px" }}
            className=""
          >
            When may contact you for details
          </p>
          <div style={{ marginRight: "20px", paddingBottom: "10px" }}>
            <ReactDateInputs
              value={value}
              onChange={setValue}
              style={{ width: "30px" }}
              required
            />
          </div>
        </div>
        <div
          className="d-flex mt-2 align-items-center"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{ marginLeft: "10px", fontWeight: "bold", width: "300px" }}
            className=""
          >
            Would you like to utilize Escrow service when finalizing
          </p>

          <div style={{ marginRight: "20px" }}>
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
              onChange={(e) => handleCheck(e)}
              defaultChecked={service}
            />
          </div>
        </div>

        <div className="mx-5 row">
          <div className="col-md-6 col-sm-6 col-sx-6" style={{ width: "50%"}}>
            <button
              type="submit"
              className="btn btn-block das-button"
              style={{ background: "purple", color: "white", width: "100%", whiteSpace: "normal" }}
              onClick={(e) => handleSubmit(e)}
            >
              Pay for Inspection
            </button>
          </div>
          <div className="col-md-6 col-sm-6 col-sx-6" style={{width: "50%"}}>
            <button
              type="submit"
              className="btn btn-block das-button"
              style={{ background: "purple", color: "white", width: "100%", whiteSpace: "normal" }}

              onClick={(e) => handleContact(e)}
            >
              Speak to an inspector
            </button>
          </div>
        </div>
        <div className="w-100 text-center mt-3">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" />
          <label for="no" className="" style={{ marginRight: "10px" }}>
            I have read and Agree to the{" "}
            <span style={{ color: "blue" }}>Terms and conditions</span>
          </label>
        </div>
      </form>

      <form
        className="mx-auto w-50 mt-5 dns-desktop"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div class="form-group mb-3">
          <label for="exampleInputEmail1">Name</label>
          <input
            type="name"
            class="form-control"
            id="exampleInputName"
            aria-describedby="nameHelp"
            placeholder="Enter name"
            name="name"
            required
            onChange={(e) => handle(e)}
            value={data?.name}
          />
        </div>
        <div class="form-group pb-3">
          <label for="exampleInputEmail1">Email address</label>
          <input
            type="email"
            name="email"
            required
            value={data?.email}
            onChange={(e) => handle(e)}
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="enter email address"
          />
          <small id="emailHelp" class="form-text text-muted">
            Only for sending your inpection report. Never shared with 3rd parties
          </small>
        </div>
        <div class="form-group pb-3">
          <label for="phone">Phone</label>
          <input
            type="text"
            required
            class="form-control"
            id="exampleInputPassword1"
            placeholder="Enter tel-no"
            name="phone"
            value={data?.phone}
            onChange={(e) => handle(e)}
          />
        </div>
        <div class="form-group pb-3">
          <label for="no">No of properties to be inspected</label>
          <input
            type="number"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="no of properties to be inspected"
            value={data?.inspected}
            onChange={(e) => handle(e)}
            name="inspected"
            required
          />
        </div>
        <div class="form-group pb-3">
          <label for="no">Links to properties to be inspected</label>
          <input
            placeholder="enter links of properties"
            name="location"
            value={data?.location}
            onChange={(e) => handle(e)}
            type="text"
            required
            className="form-control"
          />
        </div>
        <div class="form-group pb-3">
          <label for="no">Agent phone no</label>
          <input
            placeholder="enter agent phone no"
            name="agentphone"
            value={data?.agentphone}
            onChange={(e) => handle(e)}
            type="text"
            required
            className="form-control"
          />
        </div>
        <div class="form-group mb-4">
          <label for="no" className="pb-2">
            When may we contact you for details
          </label>
          <ReactDateInputs
            value={value}
            onChange={setValue}
            style={{ width: "30px" }}
            required
          />
        </div>

        <div class="form-group pb-3">
          <label for="no" className="" style={{ marginRight: "10px" }}>
            Would you like to utilize Escrow service when finalizing
          </label>
          <input
            type="checkbox"
            onChange={(e) => handleCheck(e)}
            defaultChecked={service}
            class="form-check-input"
            id="exampleCheck1"
          />
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-6">
            <button
              type="submit"
              className="btn btn-block w-100 das-button"
              style={{ background: "purple", color: "white" }}
              onClick={(e) => handleSubmit(e)}
            >
              Pay for Inspection
            </button>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6">
            <button
              type="submit"
              className="btn btn-block w-100 das-button"
              style={{background: "purple", color: "white" }}
              onClick={(e) => handleContact(e)}
            >
              Speak to Inspector
            </button>
          </div>
        </div>

        <div className="w-100 text-center mt-3 add-space">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" />
          <label for="no" className="" style={{ marginRight: "10px" }}>
            I have read and Agree to the{" "}
            <span style={{ color: "blue" }}>Terms and conditions</span>
          </label>
        </div>
      </form>

      <DasModal
        show={show}
        setShow={setShow}
        inspected={data?.inspected}
        data={data}
        loading={loading}
        setLoading={setLoading}
        setErr={setErr}
        setMessage={setMessage}
        service={service}
        VIDASvalue={VIDASvalue}
        setVIDASValue={setVIDASValue}
      />
    </div>
  );
}

export default DasPage;
