import React, { useState, useEffect } from "react";
import {db} from "./Firebase";
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, getDocs } from "firebase/firestore";
import Button from "@mui/material/Button";
import { format } from "date-fns";

const Blog = () => {
  const auth = getAuth()
  const [user, setUser] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [newBlog, setNewBlog] = useState({ title: "", content: "", imageUrl: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "blogs"));
      const fetchedBlogs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogs(fetchedBlogs);
      setLoading(false);
    };

    fetchBlogs();
  }, []);

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, "user@example.com", "password123");
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleAddBlog = async () => {
    if (!newBlog.title || !newBlog.content) return alert("All fields are required!");
    try {
      const docRef = await addDoc(collection(db, "blogs"), {
        ...newBlog,
        author: user.email,
        createdAt: new Date(),
      });
      setBlogs([...blogs, { id: docRef.id, ...newBlog, createdAt: new Date() }]);
      setNewBlog({ title: "", content: "", imageUrl: "" });
    } catch (error) {
      console.error("Error adding blog:", error);
    }
  };

  const shareArticle = (title, url) => {
    const text = `Check out this blog post: "${title}"!`;
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(
      url
    )}`;
    window.open(shareUrl, "_blank");
  };

  const truncateContent = (content, length) =>
    content.length > length ? `${content.substring(0, length)}...` : content;


  const shareOnTwitter = (title, url) => {
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `Check out this blog post: "${title}"`
    )}&url=${encodeURIComponent(url)}`;
    window.open(shareUrl, "_blank");
  };

  const shareOnWhatsApp = (title, url) => {
    const shareUrl = `https://wa.me/?text=${encodeURIComponent(
      `Check out this blog post: "${title}" - ${url}`
    )}`;
    window.open(shareUrl, "_blank");
  };

  const shareOnFacebook = (url) => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(shareUrl, "_blank");
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Chekam Blog</h1>

      {!user ? (
        <Button variant="outlined" color="secondary" onClick={handleSignIn}>Sign In</Button>
      ) : (
        <div>
          <p>Welcome, {user.email}</p>
          <Button variant="outlined" color="secondary" onClick={handleSignOut}>Sign Out</Button>
        </div>
      )}

      {user && (
        <div style={{ marginTop: "20px" }}>
          <h2>Create a New Blog</h2>
          <input
            type="text"
            placeholder="Title"
            value={newBlog.title}
            onChange={(e) => setNewBlog({ ...newBlog, title: e.target.value })}
            style={{ display: "block", margin: "10px 0", padding: "8px" }}
          />
          <textarea
            placeholder="Content"
            value={newBlog.content}
            onChange={(e) => setNewBlog({ ...newBlog, content: e.target.value })}
            style={{ display: "block", margin: "10px 0", padding: "8px" }}
          ></textarea>
          <input
            type="text"
            placeholder="Image URL (optional)"
            value={newBlog.imageUrl}
            onChange={(e) => setNewBlog({ ...newBlog, imageUrl: e.target.value })}
            style={{ display: "block", margin: "10px 0", padding: "8px" }}
          />
          <Button variant="outlined" color="secondary" onClick={handleAddBlog}>Add Blog</Button>
        </div>
      )}

      <h2 style={{ marginTop: "40px" }}>Blogs</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        blogs.map((blog) => (
          <div
            key={blog.id}
            style={{
              border: "1px solid #ddd",
              padding: "10px",
              marginBottom: "10px",
              borderRadius: "5px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            {blog.imageUrl && (
              <img
                src={blog.imageUrl}
                alt={blog.title}
                style={{ width: "100%", borderRadius: "5px", marginBottom: "10px" }}
              />
            )}
            <h3>{blog.title}</h3>
            <small style={{ color: "#666" }}>
              Posted on: {format(blog.createdAt.toDate(), "MMMM d, yyyy")}
            </small>
            <p>{truncateContent(blog.content, 100)}</p>
            {blog.content.length > 100 && (
              <button
                onClick={() => alert(blog.content)}
                style={{ display: "block", marginTop: "10px", color: "#007bff", cursor: "pointer" }}
              >
                Read More
              </button>
            )}
            <div style={{ marginTop: "10px" }}>
              <Button variant="outlined"
                onClick={() => shareOnTwitter(blog.title, blog.imageUrl || window.location.href)}
                style={{ marginRight: "10px", color: "#1DA1F2", cursor: "pointer" }}
              >
                Share on Twitter
              </Button>
              <Button variant="outlined"
                onClick={() => shareOnWhatsApp(blog.title, blog.imageUrl || window.location.href)}
                style={{ marginRight: "10px", color: "#25D366", cursor: "pointer" }}
              >
                Share on WhatsApp
              </Button>
              <Button variant="outlined"
                onClick={() => shareOnFacebook(blog.imageUrl || window.location.href)}
                style={{ color: "#3b5998", cursor: "pointer" }}
              >
                Share on Facebook
              </Button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Blog;
