import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
} from "@mui/material";

const PricingModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const pricingData = [
    {
      serialNumber: 1,
      evaluationChoice: "VI & DAS Only",
      skus: [
        { description: "Single Property", price: 50 },
        { description: "3 in same area (Bundle discount)", price: 100 },
        {
          description:
            "Each additional property on same order and in same area as first 3 properties in the same area",
          price: 20,
        },
      ],
    },
    {
      serialNumber: 2,
      evaluationChoice: "VI & DAS + Legal Due Diligence*",
      skus: [
        { description: "Single Property", price: 100 },
        { description: "3 in same area (Bundle discount)", price: 250 },
        {
          description:
            "Each additional property on same order and in same area as first 3 properties in the same area",
          price: 70,
        },
      ],
    },
  ];
  

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleOpen}>
        Pricing
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="pricing-modal-title">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%", // Adjust width as needed
            maxWidth: 800,
            // bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh", // Restrict height for scrollability
            overflowY: "auto", // Enable vertical scrolling
          }}
        >
          <Typography id="pricing-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            The Services provided
          </Typography>
          <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "white" }}>
              <TableCell sx={{ color: "secondary", fontWeight: "bold" }}>S/N</TableCell>
              <TableCell sx={{ color: "secondary", fontWeight: "bold" }}>Choice of Evaluation</TableCell>
              <TableCell sx={{ color: "secondary", fontWeight: "bold" }}>SKUs</TableCell>
              <TableCell sx={{ color: "secondary", fontWeight: "bold" }}>Price ($)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pricingData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell rowSpan={row.skus.length} sx={{ color: "white", fontWeight: "bold" }}>
                    {row.serialNumber}
                  </TableCell>
                  <TableCell rowSpan={row.skus.length} sx={{ color: "white", fontWeight: "bold" }}>
                    {row.evaluationChoice}
                  </TableCell>
                  <TableCell  sx={{ color: "white", fontWeight: "bold" }}>{row.skus[0].description}</TableCell>
                  <TableCell  sx={{ color: "white", fontWeight: "bold" }}>{row.skus[0].price}</TableCell>
                </TableRow>
                {row.skus.slice(1).map((sku, skuIndex) => (
                  <TableRow key={skuIndex}>
                    <TableCell  sx={{ color: "white", fontWeight: "bold" }}>{sku.description}</TableCell>
                    <TableCell  sx={{ color: "white", fontWeight: "bold" }}>{sku.price}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            sx={{ mt: 2, display: "block", marginLeft: "auto", marginRight: "auto" }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default PricingModal;
